/**
 *
 * Custom variables
 *
 */

@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../node_modules/bootstrap/scss/utilities';

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-base: $font-family-sans-serif;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge((0: 0, 1: ($spacer * 0.25), 2: ($spacer * 0.5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3), 6: ($spacer * 6.25), 7: ($spacer * 12.5)), $spacers);

/* Color variants - https://codepen.io/migli/pen/QaGNVp */

$white: #fff;
$black: #000;

$blue: #0e73cc;
$red: #fc4848;
$orange: #fd7e14;
$pink: #e91e63;
$indigo: #3f51b5;
$yellow: #ffc107;
$green: #0f9e7b;
$cyan: #00c2db;
$gray: #8c8476;
$gray-dark: #38352f;
$color-variants: (
    100: -10%,
    200: -25%,
    300: -50%,
    400: -75%,
    500: 100%,
    600: 75%,
    700: 50%,
    800: 25%,
    900: 15%
);

$colors: ();
$colors: map-merge(

    (
        'blue': $blue,
        'red': $red,
        'pink': $pink,
        'yellow': $yellow,
        'gray': $gray,
        'gray-dark': $gray-dark,
        'primary': $primary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger
    ),
    $colors
);

/*  Add color variants to $colors*/
@each $color-name, $color in $colors {
    @each $variant-name, $percent in $color-variants {
        $mix-color: if($percent < 0%, white, black);
        $colors: map-merge($colors,  ($color-name+'-'+$variant-name: mix($color, $mix-color, abs($percent))));
    }
}

/* remap grays */

$gray-100: map-get($colors, gray-100);
$gray-200: map-get($colors, gray-200);
$gray-300: map-get($colors, gray-300);
$gray-400: map-get($colors, gray-400);
$gray-500: map-get($colors, gray-500);
$gray-600: map-get($colors, gray-600);
$gray-700: map-get($colors, gray-700);
$gray-800: map-get($colors, gray-800);
$gray-900: map-get($colors, gray-900);

/* remap colors */

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: ();
$theme-colors: map-merge(('primary': $primary, 'secondary': $secondary, 'success': $success, 'info': $info, 'warning': $warning, 'danger': $danger, 'light': $light, 'dark': $dark), $theme-colors);

$enable-hover-media-query: true;

/* from Bootstrap 4 utilities - replaced $theme-colors with $colors */

/* text colors*/
@each $color, $value in $colors {
    @include text-emphasis-variant('.text-#{$color}', $value);
}

/* background colors*/
@each $color, $value in $colors {
    @include bg-variant('.bg-#{$color}', $value);
}

/* background text contrast*/
@each $color, $value in $colors {
    .bg-#{$color} {
        color: color-yiq($value);
    }
}

/* border colors*/
@each $color, $value in $colors {
    .border-#{$color} {
        border-color: $value !important;
    }
}

$font-size-base: 15px;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);

$h1-font-size: $font-size-base * 2.6666666666666665;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;

$font-weight-base: $font-weight-normal;
$dt-font-weight: $font-weight-bold;
$nested-kbd-font-weight: $font-weight-bold;
$badge-border-radius: $border-radius-sm;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.15em;
$badge-padding-x: 0.5em;
$alert-link-font-weight: $font-weight-bold;
$close-font-weight: $font-weight-bold;

$enable-shadows: true;

$body-color: #2a2d2d;
$body-light-color: $gray-400;

$navbar-nav-link-padding-x: 1rem;
$navbar-brand-padding-y: 0;
$navbar-light-active-color: map-get($colors, danger-500);

$nav-pills-border-radius: 0;

$transition-collapse: height 0.35s ease !important;
